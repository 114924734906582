<template>
  <div>
    <div class="contents">

    <div class="mainbox">
       <div class="jiqiren"><span>正在工作的机器人微信号：</span>{{shopArr.name}}</div>
      <div class="checkTxt">设置【订单处理员】<span>（负责接收收单消息，订单异常信息，建议选择订单客服）</span></div>
      <div class="checkBox">
        <div class="saveBtn">
          <p>选好了【订单处理员】之后，请点击保存</p>
          <el-button type="primary" size="medium" @click="submitFun">保存设置</el-button></div>
      <el-transfer
        v-model="contactValue"
        :titles="title"
        filterable
        :right-default-checked="contactAdmin"
        filter-placeholder="请输入微信昵称"
        :data="contactData"
      >
      </el-transfer>
      </div>

      <div class="checkTxt">设置【数据主管】<span>（负责接收每日数据报表，业绩报表，建议选择相关管理人员）</span></div>
      <div class="checkBox">
        <div class="saveBtn"><p>选好了[数据主管]之后，请点击保存</p><el-button type="primary" size="medium" @click="dataFun">保存设置</el-button></div>
      <el-transfer
        v-model="proValue"
        :titles="titless"
        filterable
        filter-placeholder="请输入微信昵称"
        :right-default-checked="proAdmin"
        :data="contactData"
      />
      </div>


    
    </div>
       <!-- <el-table
        :data="tableData"
        border
        :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
        style="width: 100%">
        <el-table-column
          type="selection"
          width="50" header-align="center" align="center">
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          width="200">
        </el-table-column>
         <el-table-column
          prop="createTime"
          label="登录时间" width="200">
        </el-table-column>
         <el-table-column
      label="操作"
       header-align="center" align="left">
      <template slot-scope="scope">
        <el-button @click="loginClick(scope.row)" type="primary" plain size="small">重新登录</el-button>
        <el-button @click="contactClick(scope.row)" type="primary" plain size="small">联系人设置</el-button>
        <el-button @click="qunClick(scope.row)" type="primary" plain size="small">群组设置</el-button>
      </template>
    </el-table-column>
      </el-table>
      <div class="paginations">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="changePage" :current-page="page" :page-size="pageSize"
            :total="total">
          </el-pagination>
      </div>
      </div>
    </div>

     <el-dialog title="扫码创建机器人" :visible.sync="shopflag" :before-close="handleClose" width="600px">
       <div class="shopLogo" v-loading="loading">
          <div class="shopol" ref="qrCodeUrl"></div>
          <p>请用微信扫一扫</p>
       </div> 
    </el-dialog> -->
  </div>
  </div>
</template>

<script>
import { contactFun, setReceiver } from "@/api/robot";
import { MessageBox } from 'element-ui'


export default {
  name: 'robotContact',
  data() {
    return {
      searchForm: {
        name: '',
      },
      total: 0,
      page: 1,
      contactData: [],
      contactValue: [],
      allContact: [],
      proValue: [],
      contactAdmin: [],
      proAdmin: [],
      pageSize: 20,
      shopflag: false,
      shopArr: {},
      loading: false,
      activeName: 'first',
      title: ['好友列表', '已选订单处理员'],
      titless: ['好友列表', '已选数据主管'],
      value: [],
      filterMethod(query, item) {
        return item.spell.indexOf(query) > -1;
      },
      multipleSelection: []
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleClose(){
      this.shopflag = false;
      clearInterval(timeVal)
    },
    getTopSearch(e){
      var that = this
      var datas = that.searchForm
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.orderByCreated = "DESC";
      contactFun(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.tableData = res.data.rows
          that.total = res.data.totalRows
        }else{
          this.$message.error(res.message)
        }
      })
    },
    getContact(botId){
      var that = this
      var datas = {
        botId: botId
      }
      contactFun(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          var dataTree = res.data.contacts
          that.allContact = dataTree
          dataTree.map((item)=>{
            var objs ={
              key: item.id, 
              label: item.name
            }
            that.contactData.push(objs)
          })
          that.shopArr= res.data.robot
          var yixuanTree = res.data.processors
          yixuanTree.map((item)=>{
            that.contactValue.push(item.wxId)
          })

          var proTree = res.data.receivers
          proTree.map((item)=>{
            that.proValue.push(item.wxId)
          })
    
        }else{
          that.$message.error(res.message)
        }
      })
    },
    cancalFun(){
      this.$router.go(-1)
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
    shouquan(){
      var that = this
      that.shopflag = true;
      that.getPlat()
    },
    contactClick(){
      this.$router.push({
        path: "/goods/detail"
      })
    },
    submitFun(){
      console.log(this.contactValue)
      var that = this
      var receivers=[]
      if(that.contactValue.length === 0){
        that.$message.error('请选择订单处理员')
        return
      }else{
        that.allContact.map((item)=>{
          that.contactValue.map((its)=>{
            if(item.id===its){
              receivers.push(item)
            }
          })
        })
      }
      var objs={
        "botId": that.shopArr.botId,
        "receivers": receivers,
        "type": 0,
      }
      setReceiver(objs).then(res => {
        if(res.code ===200) {
          that.$message({
            message: '成功设置订单处理员',
            type: 'success'
          });
        }else{
          that.$message.error(res.message)
        }
      })
    },
    dataFun(){
      console.log(this.proValue)
      var that = this
      var receivers=[]
      if(that.proValue.length === 0){
        that.$message.error('请选择订单处理员')
        return
      }else{
        that.allContact.map((item)=>{
          that.proValue.map((its)=>{
            if(item.id===its){
              receivers.push(item)
            }
          })
        })
      }
      var objs={
        "botId": that.shopArr.botId,
        "receivers": receivers,
        "type": 1,
      }
      setReceiver(objs).then(res => {
        if(res.code ===200) {
          that.$message({
            message: '成功设置数据主管',
            type: 'success'
          });
        }else{
          that.$message.error(res.message)
        }
      })
    },
    loginClick(row){
      var that = this
      that.shopflag = true;
      that.chongPlat(row)
    }
  },
  mounted(){
    var botId = this.$route.query.id
    console.log(botId)
    this.getContact(botId)
  }
}
</script>

<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}

.el-button--primary {
  color: #FFF;
  background-color: #07C160;
  border-color: #07C160;
}
.jiqiren{
  padding: 0px 0 20px;
  display: block;
  font-size: 18px;
  line-height:40px;
  color: #07C160;
  font-weight: bold;
}
.jiqiren span{
  color: #999;
  font-weight: normal;
}
.checkTxt{
  font-size: 18px;
  color: #333;
  display: block;
  line-height:44px;
  text-align:left;
  border: 1px solid #eee;
  padding: 10px 30px;
  margin-bottom: 20px;
  font-weight: 450;
}
.checkTxt span{ font-size:12px; color: #999; font-weight: normal;}
.saveBtn{ float: right; padding-top: 210px; padding-right: 10%; text-align: center; display: block;}
.saveBtn p{ font-size:12px; color: #ff0000; font-weight: normal; padding-bottom: 10px;}
.checkBox{
  display: block;
  overflow: hidden;
  padding: 20px 80px 100px;
  margin: 0 auto;
  min-width: 900px;
}

</style>